import React from "react";
import "./SubstituteTicket.css";
import "bootstrap/dist/css/bootstrap.min.css";
import airlinrlogo from "assets/img/airlinrlogo.png";
import MDTypography from "components/MDTypography";
import plane from "assets/img/plane.svg";
import MDButton from "components/MDButton";

const SubstituteTicket = ({ substitutes, loading, current_price }) => {
  const getSaving = (item_price, ticket_price) => {
    const itemPrice = parseFloat(item_price.replace(/[^\d.-]/g, ""));
    const ticketPrice = parseFloat(ticket_price.replace(/[^\d.-]/g, ""));
    const savings = (itemPrice - ticketPrice).toFixed(2);
    return parseFloat(savings);
  };

  const getTime = (departure, arrival) => {
    const formatDateTime = (dateTimeStr) => {
      const [date, time, modifier] = dateTimeStr?.split(" ");
      let [hours, minutes] = time?.split(":");
      if (modifier === "PM" && hours !== "12") {
        hours = parseInt(hours, 10) + 12;
      } else if (modifier === "AM" && hours === "12") {
        hours = "00";
      }
      return new Date(`${date}T${hours}:${minutes}:00`);
    };
    const departureDate = formatDateTime(departure);
    const arrivalDate = formatDateTime(arrival);
    const differenceInMilliseconds = arrivalDate - departureDate;
    const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );

    return `${hours}hr ${minutes}min`;
  };

  return (
    <>
      <MDTypography variant="p" className="p-title">
        Substitute Tickets
      </MDTypography>
      {loading ? (
        <>
          <h5>Loading ..</h5>
        </>
      ) : (
        <>
          {substitutes.length > 0 ? (
            substitutes.map((ticket, index) => (
              <div key={index} className="container-fluid p-3 mb-3 subtiktmain">
                <div
                  className="row align-items-center"
                  style={{ alignItems: "center" }}
                >
                  <div className="col-md-4 d-flex airlinetkt">
                    <div
                      className="col-md-6 d-flex"
                      style={{ alignItems: "center" }}
                    >
                      <img src={airlinrlogo} alt="" className="img-fluid" />
                      <div className="ticktnam mx-2">
                        <p className="ticktptag12">{ticket.airline_name}</p>
                        <p className="ticktptag21" style={{ fontSize: "13px" }}>
                          <span
                            style={{
                              color: "#94C353",
                              fontWeight: "bold",
                              fontSize: "13px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {ticket.match_percentage}%
                          </span>
                          &nbsp;Matched</p>
                      </div>
                    </div>
                    <div className="col-md-6 " style={{ marginTop: "20px" }}>
                      <p className="fstptag">{ticket?.price}</p>
                      <p className="sndptag" style={{ whiteSpace: "nowrap" }}>
                        You Can Save{" "}
                        <span
                          style={{
                            color: "#94C353",
                            fontWeight: "bold",
                            fontSize: "13px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {ticket?.saved_amount}
                          {/* $ {getSaving(current_price, ticket.price)} */}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 Dtime">
                    <div className="Dtimelist">
                      <ul className="ul-list">
                        <p className="ullist">
                          {ticket?.departure_time?.split(" ")?.[0]}
                          <span className="depature_time">
                            {`${ticket?.departure_time?.split(" ")?.[1]} ${ticket?.departure_time?.split(" ")?.[2]
                              }`}
                          </span>
                        </p>

                        <p className="depature_code">
                          {ticket.departure_iata_code}
                        </p>
                      </ul>
                    </div>
                    <div className="Dtimelist">
                      <ul style={{ padding: "0" }}>
                        <img
                          src={plane}
                          alt="Custom Plane"
                          width="68px"
                          style={{ marginTop: "20px" }}
                        />
                        <li className="ullist">
                          {getTime(
                            ticket?.departure_time,
                            ticket?.arrival_time
                          )}
                        </li>
                      </ul>
                    </div>
                    <div className="Dtimelist">
                      <ul className="ul-list">
                        <p className="ullist">
                          {ticket?.arrival_time?.split(" ")?.[0]}
                          <span className="depature_time">
                            {`${ticket?.arrival_time?.split(" ")?.[1]} ${ticket?.arrival_time?.split(" ")?.[2]
                              }`}
                          </span>
                        </p>
                        <p className="depature_code">
                          {ticket.arrival_iata_code}
                        </p>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4 d-flex airlinetkt">
                    <div className="col-md-12">
                      <div className="ticket_detail_butns">
                        <MDButton className="accept_butn">ACCEPT</MDButton>
                        <MDButton className="reject_butn">REJECT</MDButton>
                        <MDButton className="eliminate_butn">
                          Eliminate
                        </MDButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No substitute offers available</p>
          )}
        </>
      )}
    </>
  );
};

export default SubstituteTicket;
