import MDBox from "components/MDBox";
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDTypography from "components/MDTypography";
import AirlineLogo from "assets/user-dash/airlineLogo.png";
import { Card } from "@mui/material";
import FlightImg from "assets/user-dash/flight.png";
import MDButton from "components/MDButton";
import airlinrlogo from "assets/img/airlinrlogo.png";
import plane from "assets/img/plane.svg";
import { fetchBookings } from "utils/api";
import { fetchFlightOffers } from "utils/api";
import Loader from "components/Loader";
import { Link, useNavigate } from "react-router-dom";
import { encodeIdWithType } from "utils/encoder";
function BookingCard({ flights, loading }) {
  console.log("flights data", flights);
  const navigate = useNavigate();

  const getTime = (departure, arrival) => {
    const formatDateTime = (dateTimeStr) => {
      const [date, time, modifier] = dateTimeStr?.split(" ");
      let [hours, minutes] = time?.split(":");
      if (modifier === "PM" && hours !== "12") {
        hours = parseInt(hours, 10) + 12;
      } else if (modifier === "AM" && hours === "12") {
        hours = "00";
      }
      return new Date(`${date}T${hours}:${minutes}:00`);
    };
    const departureDate = formatDateTime(departure);
    const arrivalDate = formatDateTime(arrival);
    const differenceInMilliseconds = arrivalDate - departureDate;
    const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );

    return `${hours}hr ${minutes}min`;
  };

  const getSaving = (item_price, ticket_price) => {
    const itemPrice = parseFloat(item_price.replace(/[^\d.-]/g, ""));
    const ticketPrice = parseFloat(ticket_price.replace(/[^\d.-]/g, ""));
    const savings = (itemPrice - ticketPrice).toFixed(2);
    return parseFloat(savings);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "short", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate.replace(",", "");
  };

  const redirection = (id) => {
    let final_id = encodeIdWithType(id, "flights");
    navigate(`/price-monitor/${final_id}`);
  };

  return (
    <MDBox py={3}>
      <MDTypography variant="h6">My Bookings</MDTypography>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {flights.length > 0 &&
            flights?.map((item, i) => (
              <Accordion className="mb-3" defaultExpanded={i === 0} key={i}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <div className="airline_header row">
                    <div className="airline_data col-md-4">
                      <img src={AirlineLogo} alt="airline logo" />
                      <div className="airline_title_section">
                        <MDTypography
                          variant="h6"
                          color="white"
                          className="airline_heading"
                        >
                          {item?.airline}
                        </MDTypography>
                        <MDTypography
                          variant="p"
                          color="white"
                          className="ticket_title"
                        >
                          {item.return_date ? "Round Trip" : "Oneway Ticket"}
                        </MDTypography>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <MDTypography
                        variant="h5"
                        color="white"
                        className="flight_details"
                      >
                        {item?.price}
                      </MDTypography>
                    </div>
                    <div className="ticket_detail_heading col-md-5">
                      <MDTypography variant="h6" color="white">
                        {item?.departure_city?.split(",")[0]}-to-
                        {item?.destination_city?.split(",")[0]}
                      </MDTypography>
                      <div className="ticket_details">
                        <div>
                          <MDTypography
                            variant="p"
                            color="white"
                            className="ticket_title"
                          >
                            {item?.departure_date} {item?.departure_time}
                          </MDTypography>
                          <MDTypography variant="p" color="white"
                            className="ticket_title">
                            {item?.return_date &&  item?.return_date + " " +  item?.Arrives_time} 
                          </MDTypography>
                        </div>

                        <span>|</span>
                        <MDTypography
                          variant="p"
                          color="white"
                          className="ticket_title"
                        >
                          1 Adult
                        </MDTypography>
                        <span>|</span>
                        <MDTypography
                          variant="p"
                          color="white"
                          className="ticket_title"
                        >
                          {item?.flight_class}
                        </MDTypography>
                      </div>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="substitues_section">
                  <div className="">
                    <div className="substitutes my-2">
                      <MDTypography variant="h6" color="white">
                        Substitutes Tickets
                      </MDTypography>
                      {item?.substitutes && item?.substitutes?.length > 0 ? (
                        <button
                          className="view_all"
                          onClick={() => redirection(item?.id)}
                        >
                          View All
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                    {loading ? (
                      <>
                        <MDTypography
                          className="text-center"
                          variant="h6"
                          color="white"
                        >
                          Loading...
                        </MDTypography>
                      </>
                    ) : (
                      <>
                        {item?.substitutes && item?.substitutes?.length > 0 ? (
                          item?.substitutes
                            ?.slice(0, 3)
                            ?.map((ticket, index) => (
                              <div
                                key={index}
                                className="container-fluid p-3 mb-3 subtiktmain"
                              >
                                <div
                                  className="row align-items-center"
                                  style={{ alignItems: "center" }}
                                >
                                  <div className="col-md-4 d-flex airlinetkt">
                                    <div
                                      className="col-md-6 d-flex"
                                      style={{ alignItems: "center" }}
                                    >
                                      <img
                                        src={airlinrlogo}
                                        alt=""
                                        className="img-fluid"
                                      />
                                      <div className="ticktnam mx-2">
                                        <p className="ticktptag12">
                                          {ticket.airline_name}
                                        </p>
                                        <p className="flight_class_name">
                                          {item?.flight_class}
                                        </p>
                                        <p
                                          className="ticktptag21"
                                          style={{ fontSize: "13px" }}
                                        >
                                          <span
                                            style={{
                                              color: "#94C353",
                                              fontWeight: "bold",
                                              fontSize: "13px",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            {ticket.match_percentage}%
                                          </span>
                                          &nbsp;Matched
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      className="col-md-6 "
                                      style={{ marginTop: "20px" }}
                                    >
                                      <p className="fstptag">{ticket?.price}</p>
                                      <p
                                        className="sndptag"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        You Can Save{" "}
                                        <span
                                          style={{
                                            color: "#94C353",
                                            fontWeight: "bold",
                                            fontSize: "13px",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {ticket?.saved_amount}
                                          {/* $ {getSaving(item?.price, ticket.price)} */}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-md-4 Dtime">
                                    <div className="Dtimelist">
                                      <ul className="ul-list">
                                        <p className="ullist">
                                          {
                                            ticket?.departure_time?.split(
                                              " "
                                            )?.[0]
                                          }
                                          <span className="depature_time">
                                            {`${
                                              ticket?.departure_time?.split(
                                                " "
                                              )?.[1]
                                            } ${
                                              ticket?.departure_time?.split(
                                                " "
                                              )?.[2]
                                            }`}
                                          </span>
                                        </p>

                                        <p className="depature_code">
                                          {ticket.departure_iata_code}
                                        </p>
                                      </ul>
                                    </div>
                                    <div className="Dtimelist">
                                      <ul style={{ padding: "0" }}>
                                        <img
                                          src={plane}
                                          alt="Custom Plane"
                                          width="68px"
                                          style={{ marginTop: "20px" }}
                                        />
                                        <li className="ullist">
                                          {getTime(
                                            ticket?.departure_time,
                                            ticket?.arrival_time
                                          )}
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="Dtimelist">
                                      <ul className="ul-list">
                                        <p className="ullist">
                                          {
                                            ticket?.arrival_time?.split(
                                              " "
                                            )?.[0]
                                          }
                                          <span className="depature_time">
                                            {`${
                                              ticket?.arrival_time?.split(
                                                " "
                                              )?.[1]
                                            } ${
                                              ticket?.arrival_time?.split(
                                                " "
                                              )?.[2]
                                            }`}
                                          </span>
                                        </p>
                                        <p className="depature_code">
                                          {ticket.arrival_iata_code}
                                        </p>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="col-md-4 d-flex airlinetkt">
                                    {/* <div className="col-md-8">
                                <div className="listmn" style={{ marginTop: "15px" }}>
                                  <p className="listptag">Orlando-to-Los Angeles</p>
                                  <ol className="liststle">
                                    <li>28 Sep</li>
                                    <li>1 Adult</li>
                                    <li>Economy</li>
                                  </ol>
                                </div>
                              </div> */}
                                    <div className="col-md-12">
                                      <div className="ticket_detail_butns">
                                        <MDButton className="accept_butn">
                                          ACCEPT
                                        </MDButton>
                                        <MDButton className="reject_butn">
                                          REJECT
                                        </MDButton>
                                        <MDButton className="eliminate_butn">
                                          Eliminate
                                        </MDButton>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                        ) : (
                          <p className="no_substitute">
                            No substitute available
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
        </div>
      )}
    </MDBox>
  );
}

export default BookingCard;
