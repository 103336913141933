import axios from "axios";

axios.defaults.baseURL =
  process.env.REACT_PUBLIC_API_URL || "http://127.0.0.1:8000";


export const getUser = async () => {
  try {
    const response = await axios.get(`/users_create/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const addUser = async (data) => {
  try {
    const response = await axios.post(`/users_create/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      }
    });
    return response;
  } catch (error) {
    return error.response;
  }
}
export const getUserByID = async (id) => {
  try {
    const response = await axios.get(`/user_details/${id}/`, {
      // withCredentials: true
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

export const updateUser = async (id, data) => {
  try {
    const response = await axios.put(`/users_create/${id}/`, data, {
      // withCredentials: true
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}
export const deleteUser = async (id) => {
  try {
    const response = await axios.delete(`/users_create/${id}/`, {
      // withCredentials: true
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}