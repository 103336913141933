import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { fetchFlightOffers, fetchBookingsById } from "utils/api";
import { useLocation, useParams } from "react-router-dom";
import Loader from "components/Loader";
import { decodeIdWithType } from "utils/encoder";
import { fetchSubstitueHotel } from "utils/api";
import HotelBooking from "layouts/Price Monitor/Pricr-Monitor/Components/HotelBooking";
import UserFlightBooking from "./booking";
import UserSubstitute from "./substitute";
import { flightBookingDetails } from "utils/api";
import { HotelBookingDetails } from "utils/api";
import HotelDetail from "./HotelDetail";
import CarDetails from "./CarDetails";
import { CarBookingDetails } from "utils/api";

function FlightDetail() {
  const params = useParams();
  const [loadingBookings, setLoadingBookings] = useState(true);
  const [error, setError] = useState(null);

  const { type, id } = decodeIdWithType(params.id);
  const [booking_data, setBookingData] = useState([]);
  const [substitutes, setSubstitutes] = useState([]);

  useEffect(() => {
    if (id) {
      if (type === "flights") {
        flightBookingDetails(id)
          .then((response) => {
            setBookingData(response?.data);
          })
          .catch((error) => {
            console.log(error);
          });
        fetchFlightOffers(id).then((res) => {
          setLoadingBookings(false);
          if (res.data?.flight_offers) {
            setSubstitutes(
              Array.isArray(res.data?.flight_offers)
                ? res.data?.flight_offers
                : []
            );
          } else {
            setSubstitutes([]);
          }
        });
      } else if (type === "hotels") {
        HotelBookingDetails(id)
          .then((response) => {
            setBookingData(response?.data);
          })
          .catch((error) => {
            console.log(error);
          });
        fetchSubstitueHotel(id).then((res) => {
          setLoadingBookings(false);
          if (res.data?.hotel_offers) {
            setSubstitutes(
              Array.isArray(res.data?.hotel_offers)
                ? res.data?.hotel_offers
                : []
            );
          } else {
            setSubstitutes([]);
          }
        });
      } else if (type === "cars") {
        CarBookingDetails(id)
        .then((response) => {
          setBookingData(response?.data);
        })
        .catch((error) => {
          console.log(error);
        });
        setSubstitutes([]);
        setLoadingBookings(false);
      }
    } else {
      setError(new Error("No flight ID provided."));
      setLoadingBookings(false);
    }
  }, [id]);

  const isLoading = loadingBookings;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {type === "flights" ? (
              <>
                <UserFlightBooking details={booking_data} />
                {substitutes.length > 0 ? (
                  <UserSubstitute
                    substitutes={substitutes}
                    loading={loadingBookings}
                    current_price={booking_data[0]?.price}
                  />
                ) : (
                  <MDTypography variant="h6" color="textSecondary">
                    No Substitutes Found
                  </MDTypography>
                )}
              </>
            ) : type === "hotels" ? (
              <>
                <HotelDetail booking={booking_data} substitutes={substitutes} />
              </>
            ) : type === "cars" ? (
              <>
                <CarDetails booking={booking_data} substitutes={substitutes} />
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default FlightDetail;
