
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { getProfile, fetchBookings } from "utils/api";
import RebookCards from "./components/rebook";
import BookingCard from "./components/booking";
import HotelBook from "./components/hotelbook";
import CarRental from "./components/carRental";
import { fetchSubstitueHotel } from "utils/api";
import { fetchFlightOffers } from "utils/api";

function UserDashboard() {
  const [profile, setProfile] = useState("");
  const [active_tab, setActiveTab] = useState(1);
  const [flights, setFlights] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hotels, setHotels] = useState([]);
  const [carData, setCarData] = useState([]);

  useEffect(() => {
    setLoading(true)
    fetchBookings()
      .then(async (response) => {
        if (response.data.flights.length > 0) {
          setLoading(true);
          let data = [];
          for (let i = 0; i < response.data.flights.length; i++) {
            let item = response.data.flights[i];

            let res = await fetchFlightOffers(item.id);

            if (res.data?.flight_offers) {
              item.substitutes = Array.isArray(res.data?.flight_offers)
                ? res.data?.flight_offers
                : [];
            }
            data.push(item);
          }
          setFlights(data);
          setLoading(false);
        }

        if (response.data.hotels.length > 0) {
          setLoading(true);
          let data = [];
          for (let i = 0; i < response.data.hotels.length; i++) {
            let item = response.data.hotels[i];

            let res = await fetchSubstitueHotel(item.id);
            if (res.data?.hotel_offers) {
              item.substitutes = Array.isArray(res.data?.hotel_offers)
                ? res.data?.hotel_offers
                : [];
            }
            data.push(item);
          }
          setHotels(data);
          setLoading(false);
        }

        if (response?.data?.cars) {
          setCarData(response.data.cars);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("BookingCard: Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setProfile(JSON.parse(localStorage.getItem('active-user')))
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDTypography className="dashboard_title">
          Welcome{" "}
          <strong>{`${profile?.first_name ?? ""} ${profile?.last_name ?? ""}`}</strong>
        </MDTypography>
        <RebookCards active={active_tab} handleChange={setActiveTab} />
        {
          active_tab === 1 && (
            <BookingCard flights={flights} loading={loading} />
          )
        }
        {
          active_tab === 2 && (
            <HotelBook hotels={hotels} loading={loading} />
          )
        }
        {
          active_tab === 3 && (
            <CarRental carData={carData}  loading={loading}/>
          )
        }
      </MDBox>
    </DashboardLayout>
  );
}

export default UserDashboard;
