import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useEffect } from "react";
import MDButton from "components/MDButton";
import HotelImg from "assets/user-dash/hotelimg.png";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Card } from "@mui/material";

const HotelDetail = ({ booking, substitutes }) => {
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  return (
    <div>
      <MDBox py={3}>
        <div className="user_detail_section">
          <MDTypography variant="p" className="p-title">
            <ArrowBackIosNewIcon onClick={handleBackButtonClick} />{" "}
            {booking?.hotel_name}
          </MDTypography>
        </div>
        <Card className="container-fluid mb-2 p-3">
          <div className="row align-items-center">
            <div className="col-md-3">
              <MDTypography variant="p" color="info" className="Current-Plane">
                Your Current Hotel Booking
              </MDTypography>
            </div>
            <div className="col-md-8 dotted-gradient-border"></div>

            <div className="currentTicket mt-3">
              <div className="col-md-4 airlinetick">
                <div className="">
                  <img src={HotelImg} alt="" className="img-fluid" />
                </div>
                <div className="ticktname hotel_details_user">
                  <MDTypography
                    variant="h6"
                    className="airline_heading"
                  >
                    {booking?.hotel_name}
                  </MDTypography>
                  <MDTypography
                    variant="p"
                    className="ticket_title"
                  >
                    Check in: {booking?.check_in_date}
                  </MDTypography>
                  <MDTypography
                    variant="p"
                    className="ticket_title"
                  >
                    Check out: {booking?.check_out_date}
                  </MDTypography>

                  {/* <MDTypography variant="p" className="ticktptag2">
                          Oneway Ticket
                        </MDTypography> */}
                </div>
              </div>
              <div className="col-md-4">
                <MDTypography
                  variant="h5"
                  className="flight_details"
                >
                  {booking?.guest_name}
                </MDTypography>
                <MDTypography
                  variant="p"
                  className="ticket_title"
                >
                  Booking reference: {booking?.booking_reference}
                </MDTypography>
              </div>
              <div className="col-md-3">
                <MDTypography
                  variant="h5"
                  className="flight_details"
                >
                  {booking?.rental_price}
                </MDTypography>
                <MDTypography
                  variant="p"
                  className="ticket_title"
                >
                  {booking?.room_type}
                </MDTypography>
              </div>
            </div>
          </div>
        </Card>
      </MDBox>
      {substitutes.length > 0 ? (
        <>
          <MDTypography variant="p" className="p-title">
            Substitute Tickets
          </MDTypography>
          {substitutes.map((hotel, index) => (
            <div key={index} className="container-fluid p-3 mb-3 subtiktmain">
              <div
                className="row align-items-center"
                style={{ alignItems: "center" }}
              >
                <div className=" hotel_name_title col-md-5">
                  <MDTypography variant="h6" className="hotel_name">
                    {hotel.name}
                  </MDTypography>
                  <div className="ticket_details">
                    <MDTypography variant="p" className="ticket_title">
                      Check in: {hotel.checkInDate}
                    </MDTypography>
                  </div>
                  <div className="ticket_details">
                    <MDTypography variant="p" className="ticket_title">
                      Check out: {hotel.checkOutDate}
                    </MDTypography>
                  </div>
                </div>
                <div className="flight_price text-end col-md-2">
                  <MDTypography variant="p" className="fstptag">
                    {hotel.price.currency}&nbsp;
                    {hotel.price.total}
                  </MDTypography>
                  <p className="sndptag" style={{ whiteSpace: "nowrap" }}>
                    You Can Save{" "}
                    <span
                      style={{
                        color: "#94C353",
                        fontWeight: "bold",
                        fontSize: "13px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {hotel?.saved_amount}
                      {/* $ {getSaving(hotel?.price?.total, booking[0].rental_price)} */}
                    </span>
                  </p>
                </div>
                <div className=" col-md-2">
                  <MDTypography variant="p" className="ticket_title">
                    {hotel.guests.adults} Adult
                  </MDTypography>
                  <span className="mx-1">|</span>
                  <MDTypography
                    variant="p"
                    className="ticket_title text-capitalize"
                  >
                    {hotel.room.typeEstimated.bedType} SIZE BED
                  </MDTypography>
                </div>
                <div className="ticket_detail_butns col-md-3">
                  <MDButton className="accept_butn">ACCEPT</MDButton>
                  <MDButton className="reject_butn">REJECT</MDButton>
                  <MDButton className="eliminate_butn">Eliminate</MDButton>
                </div>
              </div>
              <div>
                <ul className="row mt-2">
                  {hotel?.description?.text?.split(",")?.map((value) => {
                    if (value !== "") {
                      return (
                        <li
                          className="col-md-3 price-monitor-amentites"
                          key={index}
                        >
                          {value}
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
          ))}
        </>
      ) : (
        <MDTypography variant="h6" color="textSecondary">
          No Substitutes Found
        </MDTypography>
      )}
    </div>
  );
};

export default HotelDetail;
