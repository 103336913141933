import MDTypography from 'components/MDTypography'
import React from 'react'
import HotelImg from "assets/user-dash/hotelimg.png";
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';


const HotelBooking = ({ booking, substitutes }) => {
    const getSaving = (item_price, ticket_price) => {
        // const itemPrice = parseFloat(item_price.replace(/[^\d.-]/g, ""));
        const ticketPrice = parseFloat(ticket_price.replace(/[^\d.-]/g, ""));
        const savings = (ticketPrice - item_price).toFixed(2);
        return parseFloat(savings);
    };
    return (
        <>
            <MDBox py={3}>
                <MDTypography variant="p" className="p-title">Price Monitor</MDTypography>
                <div className="container-fluid mb-4 mainpricemonitor">
                    <div className="row align-items-center">
                        <div className='col-md-3'>
                            <p className="Current-Plane">Your Current Hotel Booking</p>
                        </div>
                        <div className="col-md-8 dotted-gradient-border">

                        </div>
                        {booking.length > 0 ? (
                            booking.map((item, index) => (
                                <div key={index} className="currentTicket">
                                    <div className='col-md-4 airlinetick'>
                                        <div className="">
                                            <img
                                                src={HotelImg}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className='ticktname'>
                                            <MDTypography
                                                variant="h6"
                                                color="white"
                                                className="airline_heading"
                                            >
                                                {item?.hotel_name}
                                            </MDTypography>
                                            <MDTypography
                                                variant="p"
                                                color="white"
                                                className="ticket_title"
                                            >
                                                Check in: {item?.check_in_date}
                                            </MDTypography>
                                            <MDTypography
                                                variant="p"
                                                color="white"
                                                className="ticket_title"
                                            >
                                                Check out: {item?.check_out_date}
                                            </MDTypography>

                                            {/* <MDTypography variant="p" className="ticktptag2">
                          Oneway Ticket
                        </MDTypography> */}
                                        </div>

                                    </div>
                                    <div className="col-md-4">
                                        <MDTypography
                                            variant="h5"
                                            color="white"
                                            className="flight_details"
                                        >
                                            {item?.guest_name}
                                        </MDTypography>
                                        <MDTypography
                                            variant="p"
                                            color="white"
                                            className="ticket_title"
                                        >
                                            Booking reference: {item?.booking_reference}
                                        </MDTypography>
                                    </div>
                                    <div className="col-md-3">
                                        <MDTypography
                                            variant="h5"
                                            color="white"
                                            className="flight_details"
                                        >
                                            {item?.rental_price}
                                        </MDTypography>
                                        <MDTypography
                                            variant="p"
                                            color="white"
                                            className="ticket_title"
                                        >
                                            {item?.room_type}
                                        </MDTypography>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No flight available</p>
                        )}
                    </div>
                </div>
            </MDBox>
            {substitutes.length > 0 ? (
                <>
                    <MDTypography variant="p" className="p-title">
                        Substitute Tickets
                    </MDTypography>
                    {substitutes.map((hotel, index) => (
                        <div key={index} className="container-fluid p-3 mb-3 subtiktmain">
                            <div
                                className="row align-items-center"
                                style={{ alignItems: "center" }}
                            >
                                <div className=" hotel_name_title col-md-5">
                                    <MDTypography
                                        variant="h6"
                                        className="hotel_name"
                                    >
                                        {hotel.name}
                                    </MDTypography>
                                    <div className="ticket_details">
                                        <MDTypography
                                            variant="p"
                                            className="ticket_title"
                                        >
                                            Check in: {hotel.checkInDate}
                                        </MDTypography>
                                    </div>
                                    <div className="ticket_details">
                                        <MDTypography
                                            variant="p"
                                            className="ticket_title"
                                        >
                                            Check out: {hotel.checkOutDate}
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="flight_price col-md-2">
                                    <MDTypography
                                        variant="p"
                                        className="fstptag"
                                    >
                                        {hotel.price.currency}&nbsp;
                                        {hotel.price.total}
                                    </MDTypography>
                                    <p
                                        className="sndptag"
                                        style={{ whiteSpace: "nowrap" }}
                                    >
                                        You Can Save{" "}
                                        <span
                                            style={{
                                                color: "#94C353",
                                                fontWeight: "bold",
                                                fontSize: "13px",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            $ {getSaving(hotel?.price?.total, booking[0].rental_price)}
                                        </span>
                                    </p>
                                </div>
                                <div className=" col-md-2">
                                    <MDTypography
                                        variant="p"
                                        className="ticket_title"
                                    >
                                        {hotel.guests.adults} Adult
                                    </MDTypography>
                                    <span className="mx-1">|</span>
                                    <MDTypography
                                        variant="p"
                                        className="ticket_title text-capitalize"
                                    >
                                        {hotel.room.typeEstimated.bedType} SIZE BED
                                    </MDTypography>
                                </div>
                                <div className="ticket_detail_butns col-md-3">
                                    <MDButton className="accept_butn">
                                        ACCEPT
                                    </MDButton>
                                    <MDButton className="reject_butn">
                                        REJECT
                                    </MDButton>
                                    <MDButton className="eliminate_butn">
                                        Eliminate
                                    </MDButton>
                                </div>
                                {/* <div className="col-md-4 d-flex airlinetkt">
                                                <div
                                                    className="col-md-6 d-flex"
                                                    style={{ alignItems: "center" }}
                                                >
                                                    <img src={airlinrlogo} alt="" className="img-fluid" />
                                                    <div className="ticktnam mx-2">
                                                        <p className="ticktptag12">{ticket.airline_name}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 " style={{ marginTop: "20px" }}>
                                                    <p className="fstptag">{ticket?.price}</p>
                                                    <p className="sndptag" style={{ whiteSpace: "nowrap" }}>
                                                        You Can Save{" "}
                                                        <span
                                                            style={{
                                                                color: "#94C353",
                                                                fontWeight: "bold",
                                                                fontSize: "13px",
                                                                whiteSpace: "nowrap",
                                                            }}
                                                        >
                                                            $ {getSaving(current_price, ticket.price)}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 Dtime">
                                                <div className="Dtimelist">
                                                    <ul className="ul-list">
                                                        <p className="ullist">
                                                            {ticket?.departure_time?.split(" ")?.[0]}
                                                            <span className="depature_time">
                                                                {`${ticket?.departure_time?.split(" ")?.[1]} ${ticket?.departure_time?.split(" ")?.[2]
                                                                    }`}
                                                            </span>
                                                        </p>

                                                        <p className="depature_code">
                                                            {ticket.departure_iata_code}
                                                        </p>
                                                    </ul>
                                                </div>
                                                <div className="Dtimelist">
                                                    <ul style={{ padding: "0" }}>
                                                        <img
                                                            src={plane}
                                                            alt="Custom Plane"
                                                            width="68px"
                                                            style={{ marginTop: "20px" }}
                                                        />
                                                        <li className="ullist">
                                                            {getTime(
                                                                ticket?.departure_time,
                                                                ticket?.arrival_time
                                                            )}
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="Dtimelist">
                                                    <ul className="ul-list">
                                                        <p className="ullist">
                                                            {ticket?.arrival_time?.split(" ")?.[0]}
                                                            <span className="depature_time">
                                                                {`${ticket?.arrival_time?.split(" ")?.[1]} ${ticket?.arrival_time?.split(" ")?.[2]
                                                                    }`}
                                                            </span>
                                                        </p>
                                                        <p className="depature_code">
                                                            {ticket.arrival_iata_code}
                                                        </p>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-4 d-flex airlinetkt">
                                                <div className="col-md-12">
                                                    <div className="ticket_detail_butns">
                                                        <MDButton className="accept_butn">ACCEPT</MDButton>
                                                        <MDButton className="reject_butn">REJECT</MDButton>
                                                        <MDButton className="eliminate_butn">
                                                            Eliminate
                                                        </MDButton>
                                                    </div>
                                                </div>
                                            </div> */}
                            </div>
                            <div>
                                <ul className="row mt-3">
                                    {
                                        hotel.description.text?.split(",").map((value) => {
                                            if (value !== "") {
                                                return <li className="col-md-3 price-monitor-amentites" key={index}>{value}</li>
                                            }
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    ))}
                </>
            ) : (
                <MDTypography variant="h6" color="textSecondary">
                    No Substitutes Found
                </MDTypography >
            )}
        </>
    )
}

export default HotelBooking