import MDBox from "components/MDBox";
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDTypography from "components/MDTypography";
import { Card } from "@mui/material";
import Wifi from "assets/user-dash/wifi.png";
import Lift from "assets/user-dash/lift.png";
import Security from "assets/user-dash/security.png";
import Furnished from "assets/user-dash/furnished.png";
import HotelImg from "assets/user-dash/hotelimg.png";
import FlightImg from "assets/user-dash/flight.png";
import MDButton from "components/MDButton";
import { fetchBookings } from "utils/api";
import { fetchFlightOffers } from "utils/api";
import Loader from "components/Loader";
import { fetchSubstitueHotel } from "utils/api";
import { Link, useNavigate } from "react-router-dom";
import { encodeIdWithType } from "utils/encoder";

function HotelBook({hotels,loading}) {
  // const [hotels, setHotels] = React.useState([]);
  const [substitute_data, setSubstitueData] = React.useState([]);
  // const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  // React.useEffect(() => {
  //   setLoading(true);
  //   fetchBookings()
  //     .then(async (response) => {
  //       if (response.data.hotels.length > 0) {
  //         setLoading(true);
  //         let data = [];
  //         for (let i = 0; i < response.data.hotels.length; i++) {
  //           let item = response.data.hotels[i];

  //           let res = await fetchSubstitueHotel(item.id);
  //           if (res.data?.hotel_offers) {
  //             item.substitutes = Array.isArray(res.data?.hotel_offers)
  //               ? res.data?.hotel_offers
  //               : [];
  //           }
  //           data.push(item);
  //         }
  //         setHotels(data);
  //         setLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //     });
  // }, []);

  const redirection = (id) => {
    let final_id = encodeIdWithType(id, "hotels");
    navigate(`/price-monitor/${final_id}`);
  };

  const getSaving = (item_price, ticket_price) => {
    // const itemPrice = parseFloat(item_price.replace(/[^\d.-]/g, ""));
    const ticketPrice = parseFloat(ticket_price.replace(/[^\d.-]/g, ""));
    const savings = (ticketPrice - item_price).toFixed(2);
    return parseFloat(savings);
  };

  return (
    <MDBox py={3}>
      <MDTypography variant="h6">My Bookings</MDTypography>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {hotels?.length > 0
            ? hotels?.map((item, i) => {
                return (
                  <Accordion className="mb-3" defaultExpanded={i === 0} key={i}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <div className="airline_header row">
                        <div className="airline_data col-md-5">
                          <img src={HotelImg} alt="airline logo" />
                          <div className="airline_title_section">
                            <MDTypography
                              variant="h6"
                              color="white"
                              className="airline_heading"
                            >
                              {item?.hotel_name}
                            </MDTypography>
                            <MDTypography
                              variant="p"
                              color="white"
                              className="ticket_title"
                            >
                              Check in: {item?.check_in_date}
                            </MDTypography>
                            <MDTypography
                              variant="p"
                              color="white"
                              className="ticket_title"
                            >
                              Check out: {item?.check_out_date}
                            </MDTypography>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <MDTypography
                            variant="h5"
                            color="white"
                            className="flight_details"
                          >
                            {item?.guest_name}
                          </MDTypography>
                          <MDTypography
                            variant="p"
                            color="white"
                            className="ticket_title"
                          >
                            Booking reference: {item?.booking_reference}
                          </MDTypography>
                        </div>
                        <div className="col-md-3">
                          <MDTypography
                            variant="h5"
                            color="white"
                            className="flight_details"
                          >
                            {item?.rental_price}
                          </MDTypography>
                          <MDTypography
                            variant="p"
                            color="white"
                            className="ticket_title"
                          >
                            {item?.room_type}
                          </MDTypography>
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails className="substitues_section">
                      <div className="substitutes my-2">
                        <MDTypography variant="h6" color="white">
                          Substitutes Tickets
                        </MDTypography>
                        {item?.substitutes && item?.substitutes?.length > 0 ? (
                          <button
                            className="view_all"
                            onClick={() => {
                              redirection(item.id);
                            }}
                          >
                            View All
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                      {loading ? (
                        <>
                          <MDTypography
                            className="text-center"
                            variant="h6"
                            color="white"
                          >
                            Loading...
                          </MDTypography>
                        </>
                      ) : (
                        <>
                          {item.substitutes?.length > 0 ? (
                            item?.substitutes.map((hotel, index) => {
                              return (
                                <Card className="hotel_cards my-2">
                                  <div className="airline_header substitutes_card_flight row">
                                    <div className=" hotel_name_title col-md-5">
                                      <MDTypography
                                        variant="h6"
                                        className="hotel_name"
                                      >
                                        {hotel.name}
                                      </MDTypography>
                                      <div className="ticket_details">
                                        <MDTypography
                                          variant="p"
                                          className="ticket_title"
                                        >
                                          Check in: {hotel.checkInDate}
                                        </MDTypography>
                                      </div>
                                      <div className="ticket_details">
                                        <MDTypography
                                          variant="p"
                                          className="ticket_title"
                                        >
                                          Check out: {hotel.checkOutDate}
                                        </MDTypography>
                                      </div>
                                    </div>
                                    <div className="flight_price col-md-2">
                                      <MDTypography
                                        variant="p"
                                        className="fstptag"
                                      >
                                        {hotel.price.currency}&nbsp;
                                        {hotel.price.total}
                                      </MDTypography>
                                      <p
                                        className="sndptag"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        You Can Save{" "}
                                        <span
                                          style={{
                                            color: "#94C353",
                                            fontWeight: "bold",
                                            fontSize: "13px",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {hotel?.saved_amount}
                                          {/* $ {getSaving(hotel?.price?.total, item.rental_price)} */}
                                        </span>
                                      </p>
                                    </div>
                                    <div className=" col-md-2">
                                      <MDTypography
                                        variant="p"
                                        className="ticket_title"
                                      >
                                        {hotel.guests.adults} Adult
                                      </MDTypography>
                                      <span className="mx-1">|</span>
                                      <MDTypography
                                        variant="p"
                                        className="ticket_title text-capitalize"
                                      >
                                        {hotel.room.typeEstimated.bedType} SIZE
                                        BED
                                      </MDTypography>
                                    </div>
                                    <div className="ticket_detail_butns col-md-3">
                                      <MDButton className="accept_butn">
                                        ACCEPT
                                      </MDButton>
                                      <MDButton className="reject_butn">
                                        REJECT
                                      </MDButton>
                                      <MDButton className="eliminate_butn">
                                        Eliminate
                                      </MDButton>
                                    </div>
                                  </div>
                                  <div>
                                    <ul className="row">
                                      {hotel.description.text
                                        ?.split(",")
                                        .map((value) => {
                                          if (value !== "") {
                                            return (
                                              <li
                                                className="col-md-4 hotel_desc_list"
                                                key={index}
                                              >
                                                {value}
                                              </li>
                                            );
                                          }
                                        })}
                                    </ul>
                                  </div>
                                </Card>
                              );
                            })
                          ) : (
                            <p className="no_substitute">
                              No substitute available
                            </p>
                          )}
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>
                );
              })
            : ""}
        </div>
      )}
    </MDBox>
  );
}

export default HotelBook;
