import React from "react";
import CarImg from "assets/user-dash/carImage.png";
import { Card } from "@mui/material";
import MDTypography from "components/MDTypography";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";

const CarDetails = ({ booking, substitutes }) => {
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    navigate(-1);
  };
  return (
    <div>
      <div className="user_detail_section">
        <MDTypography variant="p" className="p-title">
          <ArrowBackIosNewIcon onClick={handleBackButtonClick} />{" "}
          {booking?.car_model}
        </MDTypography>
      </div>
      <Card className="mt-1">
        <div className="airline_header row">
          <div className="airline_data col-md-6">
            <img src={CarImg} alt="airline logo" />
            <div className="airline_title_section">
              <MDTypography
                variant="h6"
                color="white"
                className="air_line_title"
              >
                {booking?.car_model}
              </MDTypography>
              <MDTypography
                variant="p"
                color="white"
                className="user_ticket_title"
              >
                {`Pick up: ${booking?.pickup_date}, ${
                  booking?.pickup_location?.length > 20
                    ? `${booking?.pickup_location?.slice(0, 20)}...`
                    : booking?.pickup_location
                }`}
              </MDTypography>
              <MDTypography
                variant="p"
                color="white"
                className="user_ticket_title"
              >
                Drop: {booking?.dropoff_date},{" "}
                {booking?.dropoff_location?.length > 20
                  ? `${booking?.dropoff_location?.slice(0, 20)}...`
                  : booking?.dropoff_location}
              </MDTypography>
            </div>
          </div>
          <div className="col-md-3">
            <MDTypography variant="h6" className="airline_Text">
              {booking?.renter_name}
            </MDTypography>
            <MDTypography
              variant="p"
              color="white"
              className="user_ticket_title"
            >
              Booking reference: {booking?.booking_reference}
            </MDTypography>
          </div>

          <div className="col-md-3">
            <div className="ticket_detail_heading">
              <MDTypography variant="h6">{booking?.rental_price}</MDTypography>
              <div className="ticket_details">
                <MDTypography variant="p" className="ticket_title">
                  {booking?.rental_company}
                </MDTypography>
              </div>
              <div className="ticket_details">
                <MDTypography
                  variant="p"
                  color="white"
                  className="user_ticket_title"
                >
                  {booking?.rental_class}
                </MDTypography>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CarDetails;
