import React from 'react'
import './Tickets.css';
import MDBox from "components/MDBox";
import airlinrlogo from 'assets/img/airlinrlogo.png'
import MDTypography from 'components/MDTypography';

const FlightBooking = ({ details }) => {  
  
  return (
    <MDBox py={3}>
      <MDTypography variant="p" className="p-title">Price Monitor</MDTypography>
      <div className="container-fluid mb-4 mainpricemonitor">
        <div className="row align-items-center">
        <div className='col-md-3'>
              <p className="Current-Plane">Your Current Plane Ticket   </p>
            </div>
              <div className="col-md-8 dotted-gradient-border">
              
              </div>
          {details.length > 0 ? (
            details.map((flight, index) => (
              <div key={index} className="currentTicket">
              <div className='col-md-4 airlinetick'>
                <div className="">
                  <img
                    src={airlinrlogo}
                    alt=""
                    className="img-fluid"
                  />
                   </div>
                  <div className='ticktname'>
                    <p className="ticktptag1">{flight.airline}</p>
                    <p className='ticktptag2'>{flight.return_date ? "Round Trip" : "Oneway Ticket"}</p>
                    {/* <MDTypography variant="p" className="ticktptag2">
                          Oneway Ticket
                        </MDTypography> */}
                  </div>
               
              </div>
              <div className='col-md-6' style={{marginTop:'15px'}}>
                <div className='listmain'>
                  <p className='listmainptag'>{flight?.departure_city?.split(",")[0]}-to-{flight?.destination_city?.split(",")[0]}</p>
                  <ol className='liststyle'>
                    <li>{flight?.departure_date}</li>
                    <span>|</span>
                    <li>1 Adult</li>
                    <span>|</span>
                    <li>{flight?.flight_class}</li>
                  </ol>
                </div>
              </div>
              <div className='col-md-2'>
                <p className="numberptag">{flight.price}</p>
              </div>
            </div>
            ))
          ) : (
            <p>No flight available</p>
          )}
        </div>
      </div>
    </MDBox>
  );
}

export default FlightBooking;