import axios from "axios";

axios.defaults.baseURL =
  process.env.REACT_APP_API_URL || "http://airebooker.com:8000";

// http://airebooker.com/register
// http://127.0.0.1:8000

export const registerUser = async (data) => {
  try {
    const response = await axios.post("/register/", data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const loginUser = async (data) => {
  try {
    const response = await axios.post("/login/", data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const passwordReset = async (data) => {
  try {
    const response = await axios.post(`/password-reset/`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const setNewPassword = async (data) => {
  const { userId, token, formData } = data;
  try {
    const response = await axios.post(
      `/password-reset-confirm/${userId}/${token}/`,
      formData
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const verifyEmail = async (data) => {
  try {
    const response = await axios.post(`/verify_otp/`, { otp: data });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getProfile = async () => {
  try {
    const response = await axios.get(`/user_profile/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateProfile = async (data) => {
  try {
    const response = await axios.put(`/user_profile/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const fetchBookings = async () => {
  try {
    const response = await axios.get(`/flights_details/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const fetchBookingsById = async (id) => {
  try {
    const response = await axios.get(`/flights_details?id=${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error in fetchBookingsById:", error.response || error);
    return (
      error.response || { status: 500, data: { message: "Unknown error" } }
    );
  }
};

export const flightBookingDetails = async (id) => {
  try {
    const response = await axios.get(`/flight_booking_details/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error in fetchBookingsById:", error.response || error);
    return (
      error.response || { status: 500, data: { message: "Unknown error" } }
    );
  }
};
export const fetchFlightOffers = async (id) => {
  try {
    const response = await axios.get(`/flight_offers/${id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const HotelBookingDetails = async (id) => {
  try {
    const response = await axios.get(`/hotel_booking_flight_details/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error in fetchBookingsById:", error.response || error);
    return (
      error.response || { status: 500, data: { message: "Unknown error" } }
    );
  }
};

export const CarBookingDetails = async (id) => {
  try {
    const response = await axios.get(`/car_booking_details/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error in fetchBookingsById:", error.response || error);
    return (
      error.response || { status: 500, data: { message: "Unknown error" } }
    );
  }
};

export const fetchSubstitueHotel = async (id) => {
  try {
    const response = await axios.get(`/amadeus_hotels/${id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateUserPassword = async (data) => {
  try {
    const response = await axios.put(`/change-password/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getAdminList = async () => {
  try {
    const response = await axios.get(`/admin_users/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getAdminByID = async (id) => {
  try {
    const response = await axios.get(`/admin_users/${id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateAdmin = async (id, data) => {
  try {
    const response = await axios.put(`/admin_users/${id}/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const deleteAdminData = async (id) => {
  try {
    const response = await axios.delete(`/admin_users/${id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const userPermission = async (data) => {
  try {
    const response = await axios.post(`/assign_user_permissions/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPermissionByID = async (id) => {
  try {
    const response = await axios.get(`/get_user_permissions/?user_id=${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};


export const updateUserStatus = async (id, data) => {
  try {
    const response = await axios.post(`/users_deactivate/${id}/`, data,{
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const contactUs = async (data) => {
  try {
    const response = await axios.post(`/contact_us/`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};