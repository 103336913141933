import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import FlightBooking from "./Components/FlightBooking";
import SubstituteTicket from "./Components/SubstituteTicket";
import { useEffect, useState } from "react";
import { fetchFlightOffers, fetchBookingsById } from "utils/api";
import { useLocation, useParams } from "react-router-dom";
import Loader from "components/Loader";
import { decodeIdWithType } from "utils/encoder";
import { fetchSubstitueHotel } from "utils/api";
import HotelBooking from "./Components/HotelBooking";

function PriceMonitor() {
  const params = useParams();
  const [loadingBookings, setLoadingBookings] = useState(true);
  const [error, setError] = useState(null);


  const { type, id } = decodeIdWithType(params.id);
  const [booking_data, setBookingData] = useState([]);
  const [substitutes, setSubstitutes] = useState([]);

  useEffect(() => {

    if (id) {
      setLoadingBookings(true);
      fetchBookingsById(id).then((response) => {
        if (response.status === 200) {
          const { cars, flights, hotels } = response?.data;
          if (cars.length > 0 || flights.length > 0 || hotels.length > 0) {
            if (type === "flights") {
              setBookingData(response.data.flights || []);
              fetchFlightOffers(id).then(res => {
                setLoadingBookings(false);
                if (res.data?.flight_offers) {
                  setSubstitutes(Array.isArray(res.data?.flight_offers)
                    ? res.data?.flight_offers
                    : [])
                } else {
                  setSubstitutes([])
                }
              })
            } else if (type === "hotels") {
              setBookingData(response.data.hotels || []);
              fetchSubstitueHotel(id).then(res => {
                setLoadingBookings(false);
                if (res.data?.hotel_offers) {
                  setSubstitutes(Array.isArray(res.data?.hotel_offers)
                    ? res.data?.hotel_offers
                    : [])
                } else {
                  setSubstitutes([])
                }
              })
            } else if (type === "cars") {
              setBookingData(response.data.cars || [])
              setSubstitutes([]);
              setLoadingBookings(false);
            }
          } else {
            setError(new Error("No booking data found"));
            return;
          }
        } else {
          setError(new Error("Failed to fetch bookings. Status: " + response.status + ". Message: " + (response.data?.message || "No message")));
        }
      })
        .catch((error) => setError(error))
        .finally(() => setLoadingBookings(false));
    } else {
      setError(new Error("No flight ID provided."));
      setLoadingBookings(false);
    }
  }, [id]);

  const isLoading = loadingBookings

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {type === "flights" ? (
              <>
                <FlightBooking details={booking_data} />
                {substitutes.length > 0 ? (
                  <SubstituteTicket substitutes={substitutes} loading={loadingBookings} current_price={booking_data[0].price} />
                ) : (
                  <MDTypography variant="h6" color="textSecondary">
                    No Substitutes Found
                  </MDTypography>
                )}
              </>
            ) : type === "hotels" ? (
              <>
               <HotelBooking booking={booking_data} substitutes={substitutes}/>
              </>
            ) : (
              <></>
            )
            }
          </>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default PriceMonitor;
