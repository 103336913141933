import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import Profile from "layouts/profile";
import Icon from "@mui/material/Icon";
import AdminManagment from "layouts/AdminManagment";
import Permissions from "layouts/Permissions";

const adminRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "User Management",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/user-management",
    component: <Tables />,
  },
  {
    type: "collapse",
    name: "Admin Management",
    key: "admin",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/admin-management",
    component: <AdminManagment />,
  },
  {
    type: "collapse",
    name: "Permission",
    key: "permission",
    icon: <Icon fontSize="small">Permission</Icon>,
    route: "/permissions",
    component: <Permissions />,
  },
  // {
  //   type: "collapse",
  //   name: "Booking and Price",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/booking-price",
  //   component: <Billing />,
  // },

  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
];

export default adminRoutes;
