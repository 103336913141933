import React from "react";
import MDBox from "components/MDBox";
import airlinrlogo from "assets/user-dash/airplane.png";
import MDTypography from "components/MDTypography";
import { Card } from "@mui/material";
import plane from "assets/img/plane.svg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";

const UserFlightBooking = ({ details }) => {
  const getTime = (departure, arrival) => {
    const formatDateTime = (dateTimeStr) => {
      const [date, time, modifier] = dateTimeStr?.split(" ");
      let [hours, minutes] = time?.split(":");
      if (modifier === "PM" && hours !== "12") {
        hours = parseInt(hours, 10) + 12;
      } else if (modifier === "AM" && hours === "12") {
        hours = "00";
      }
      return new Date(`${date}T${hours}:${minutes}:00`);
    };
    const departureDate = formatDateTime(departure);
    const arrivalDate = formatDateTime(arrival);
    const differenceInMilliseconds = arrivalDate - departureDate;
    const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );

    return `${hours}hr ${minutes}min`;
  };

  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  return (
    <MDBox py={3}>
      <div className="user_detail_section">
        <MDTypography variant="p" className="p-title">
          <ArrowBackIosNewIcon onClick={handleBackButtonClick} />{" "}
          {details?.departure_city}-to-{details?.destination_city}
        </MDTypography>
      </div>
      <Card>
        <div className="row p-3">
          <div className="col-md-5">
            <div className="airplane_details">
              <img src={airlinrlogo} />
              <div className="ml-1">
                <MDTypography variant="h6">
                  {details?.departure_city?.split(",")[0]}-to-
                  {details?.destination_city?.split(",")[0]}
                </MDTypography>
                <div className="ticket_details">
                  <MDTypography variant="p" className="ticket_title">
                    {details?.departure_date}
                    {details?.return_date && "/" + details?.return_date}
                  </MDTypography>
                  <span>|</span>
                  <MDTypography variant="p" className="ticket_title">
                    1 Adult
                  </MDTypography>
                  <span>|</span>
                  <MDTypography variant="p" className="ticket_title">
                    {details?.flight_class}
                  </MDTypography>
                </div>
                <MDTypography variant="h6" className="total_charges">
                  {details?.total_charges}
                </MDTypography>
              </div>
            </div>
          </div>

          <div className="col-md-3 Dtime">
            <div className="Dtimelist">
              <ul className="ul-list">
                <p className="ullist">{details?.departure_time}</p>

                <p className="depature_code">{details?.departure_city}</p>
              </ul>
            </div>
            <div className="Dtimelist">
              <ul style={{ padding: "0" }}>
                <img
                  src={plane}
                  alt="Custom Plane"
                  width="70px"
                  style={{ marginTop: "20px" }}
                />
                <li className="ullist">
                  {/* {getTime(details?.departure_time, details?.Arrives_time)} */}
                </li>
              </ul>
            </div>
            <div className="Dtimelist">
              <ul className="ul-list">
                <p className="ullist">{details?.Arrives_time}</p>
                <p className="depature_code">{details?.destination_city}</p>
              </ul>
            </div>
          </div>
        </div>
      </Card>
    </MDBox>
  );
};

export default UserFlightBooking;
