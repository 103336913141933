import { useState, useEffect, useMemo } from "react";
import "./App.css";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import AddUser from "layouts/tables/data/addUser";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import routes from "routes";
import { ToastContainer } from "react-toastify";
import userRoutes from "userRoutes";
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import Login from "layouts/authentication/sign-in";
import ResetPassword from "layouts/authentication/reset-password";
import Logo from "assets/images/auth/logo.png";
import NewPassword from "layouts/authentication/NewPassword";
import Register from "layouts/authentication/sign-up";
import VerifyEmail from "layouts/authentication/VerifyEmail";
// import FrontPageHome from "Front-Page/FrontPageHome";
import { getProfile } from "utils/api";
import PriceMonitor from "./layouts/Price Monitor/Pricr-Monitor";
import Home from "layouts/Home";
import ViewUserDetail from "layouts/tables/viewUser";
import Subscription from "layouts/subscription";
import AddAdmin from "layouts/AdminManagment/addAdmin";
import FrontPageHome from "layouts/Front-Page/FrontPageHome";
import UserDetails from "layouts/tables/userDetails";
import FlightDetail from "layouts/tables/flightDetail";
import adminRoutes from "adminRoutes";
import Pricing from "layouts/subscription/pricing";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const [profile, setProfile] = useState("");
  const [routeData, setRouteData] = useState([]);
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("user_access_token");
    if (token) {
      getProfile()
        .then((res) => {
          if (res.status == 200) {
            setProfile(res.data);
            if (res?.data?.user_type === 2) {
              setRouteData(userRoutes);
            } else if(res?.data?.user_type === 1){
              setRouteData(adminRoutes);
            } else {
              setRouteData(routes);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [pathname]);

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes?.map((route) => {
      if (route?.collapse) {
        return getRoutes(route?.collapse);
      }

      if (route?.route) {
        return (
          <Route
            exact
            path={route?.route}
            element={route?.component}
            key={route?.key}
          />
        );
      }

      return null;
    });

  // const configsButton = (
  //   <MDBox
  //     display="flex"
  //     justifyContent="center"
  //     alignItems="center"
  //     width="3.25rem"
  //     height="3.25rem"
  //     bgColor="white"
  //     shadow="sm"
  //     borderRadius="50%"
  //     position="fixed"
  //     right="2rem"
  //     bottom="2rem"
  //     zIndex={99}
  //     color="dark"
  //     sx={{ cursor: "pointer" }}
  //     onClick={handleConfiguratorOpen}
  //   >
  //     <Icon fontSize="small" color="inherit">
  //       settings
  //     </Icon>
  //   </MDBox>
  // );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            {pathname !== "/home" && (
              <>
                <Sidenav
                  color={sidenavColor}
                  brand={
                    (transparentSidenav && !darkMode) || whiteSidenav
                      ? Logo
                      : Logo
                  }
                  brandName="Material Dashboard 2"
                  routes={routeData}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
                <Configurator />
              </>
            )}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {localStorage.getItem("user_access_token") ? (
            <>
              {getRoutes(routeData)}
              <Route path="/add-user" element={<AddUser />} />
              <Route path="/add-admin" element={<AddAdmin />} />
              <Route path="/update-admin/:id" element={<AddAdmin />} />
              <Route path="/price-monitor/:id" element={<PriceMonitor />} />
              <Route path="/edit-user/:id" element={<AddUser />} />
              <Route path="/user-detail/:id" element={<ViewUserDetail />} />
              <Route path="/user-details/:id" element={<UserDetails />} />
              <Route path="/flight-details/:id" element={<FlightDetail />} />
              <Route path="/" element={<FrontPageHome />} />
              {/* <Route path="*" element={<Navigate to="/dashboard" />} /> */}
            </>
          ) : (
            <>
              <Route path="/subscription" element={<Subscription />} />
              <Route path="*" element={<Navigate to="/" />} />
              <Route path="/login" element={<Login />} />
              <Route path="/verify-email" element={<VerifyEmail />} />
              <Route path="/register" element={<Register />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/" element={<FrontPageHome />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route
                path="/password-reset-confirm/:userId/:token/"
                element={<NewPassword />}
              />
            </>
          )}
        </Routes>
        <ToastContainer />
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          {pathname !== "/home" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={
                  (transparentSidenav && !darkMode) || whiteSidenav
                    ? Logo
                    : Logo
                }
                brandName=""
                routes={routeData}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
            </>
          )}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {localStorage.getItem("user_access_token") ? (
          <>
            <Route path="/add-user" element={<AddUser />} />
            <Route path="/add-admin" element={<AddAdmin />} />
            <Route path="/" index element={<FrontPageHome />} />
            <Route path="/update-admin/:id" element={<AddAdmin />} />
            <Route path="/edit-user/:id" element={<AddUser />} />
            <Route path="/user-detail/:id" element={<ViewUserDetail />} />
            <Route path="/user-details/:id" element={<UserDetails />} />
            <Route path="/flight-details/:id" element={<FlightDetail />} />
            <Route path="/price-monitor/:id" element={<PriceMonitor />} />
            {/* <Route path="*" element={<Navigate to="/dashboard" />} /> */}
            {getRoutes(routeData)}
          </>
        ) : (
          <>
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/" index element={<FrontPageHome />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route
              path="/password-reset-confirm/:userId/:token/"
              element={<NewPassword />}
            />
          </>
        )}
      </Routes>
      <ToastContainer />
    </ThemeProvider>
  );
}
