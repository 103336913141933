import React from "react";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import DiamondIcon from "@mui/icons-material/Diamond";
import { Button, Grid } from "@mui/material";
import BullseyeArrow from "assets/user-dash/bullseye-arrow.png";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Navbar from "layouts/Front-Page/Navbar";
import NewsletterFooter from "layouts/Front-Page/NewsletterFooter";
import { useNavigate } from "react-router-dom";

function Subscription() {

  const navigate = useNavigate()

  const handleSubscrition = (value) => {
    const token = localStorage.getItem("user_access_token")
    if(token){
      navigate("/pricing", {state : value})
    }else{
      navigate("/login")
    }
  }
  return (
    <BasicLayout>
      <div className="pt-1">
        <Navbar />
      </div>

      <Grid container justifyContent="center">
        <Grid item xs={12} md={10} lg={10}>
          <MDBox pt={6} pb={3}>
            <div className="my-2 text-center">
              <MDTypography variant="h5">Pricing Plans</MDTypography>
            </div>
            <Card>
              <Grid p={3} container justifyContent="center">
                <Grid item xs={12} md={4} p={2}>
                  <Card className="plans_card">
                    <img
                      src={BullseyeArrow}
                      width={45}
                      className="bullseye_imgs"
                    />
                    <MDTypography variant="p" mt={1}>
                      Basic
                    </MDTypography>
                    <MDTypography variant="h6">Basic</MDTypography>
                    <MDTypography variant="h1" mt={1} className="plans_name">
                      $8<span className="for_month">for month</span>
                    </MDTypography>
                    <Button onClick={()=>handleSubscrition("$8")} className="get_started_butn">Get Started</Button>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4} p={2}>
                  <Card className="premium_card">
                    <DiamondIcon className="diamond_icon" />
                    <div className="premiuim_section">
                      <MDTypography variant="p" color="white">
                        Premium
                      </MDTypography>
                      <Button className="offer_butn">Best Offer</Button>
                    </div>
                    <MDTypography
                      variant="p"
                      color="white"
                      className="for_month"
                      mt={2}
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      ut labore et dolore magna aliqua
                    </MDTypography>
                    <MDTypography
                      className="plans_name"
                      variant="h1"
                      color="white"
                      mt={1}
                    >
                      $56<span className="for_month">per month</span>
                    </MDTypography>
                    <Button onClick={()=>handleSubscrition("$56")} variant="light" className="get_started_2">
                      Get Started
                    </Button>
                  </Card>
                </Grid>
              </Grid>

              {/* <Grid p={3} container justifyContent="center">
            <Grid item xs={12} md={8} p={2}>
              <Card className="more_plans_Card">
                <div className="more_plan_section">
                  <div>
                    <Button className="plans_quantity">10 Prints</Button>
                  </div>
                  <div className="buy_now_section">
                    <MDTypography variant="h5">$10.00</MDTypography>
                    <Button className="buy_now_butn">Buy Now</Button>
                  </div>
                </div>
                <div className="more_plan_section">
                  <div>
                    <Button className="plans_quantity">10 Prints</Button>
                  </div>
                  <div className="buy_now_section">
                    <MDTypography variant="h5">$10.00</MDTypography>
                    <Button className="buy_now_butn">Buy Now</Button>
                  </div>
                </div>
                <div className="more_plan_section">
                  <div>
                    <Button className="plans_quantity">10 Prints</Button>
                  </div>
                  <div className="buy_now_section">
                    <MDTypography variant="h5">$10.00</MDTypography>
                    <Button className="buy_now_butn">Buy Now</Button>
                  </div>
                </div>
                <div className="more_plan_section">
                  <div>
                    <Button className="plans_quantity">10 Prints</Button>
                  </div>
                  <div className="buy_now_section">
                    <MDTypography variant="h5">$10.00</MDTypography>
                    <Button className="buy_now_butn">Buy Now</Button>
                  </div>
                </div>
                <div className="more_plan_section">
                  <div>
                    <Button className="plans_quantity">10 Prints</Button>
                  </div>
                  <div className="buy_now_section">
                    <MDTypography variant="h5">$10.00</MDTypography>
                    <Button className="buy_now_butn">Buy Now</Button>
                  </div>
                </div>
                <div className="more_plan_section">
                  <div>
                    <Button className="plans_quantity">10 Prints</Button>
                  </div>
                  <div className="buy_now_section">
                    <MDTypography variant="h5">$10.00</MDTypography>
                    <Button className="buy_now_butn">Buy Now</Button>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid> */}
            </Card>
          </MDBox>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item md={10}>
          <NewsletterFooter />
        </Grid>
      </Grid>
    </BasicLayout>
  );
}

export default Subscription;
